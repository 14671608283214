<template>
  <div class="white--text grey lighten-3 pa-0">
    <simple-header
      hasLeftChild
      :routes="[{ title: 'خانه', url: '/' }, { title: 'تماس با ما' }]"
      title="تماس با ما"
    >
      <v-icon dark size="150" class="float-left">mdi-headset</v-icon>
    </simple-header>
    <v-container>
      <v-card class="rounded-xl moholand-shadow">
        <v-card-text class="pa-8 black--text">
          <v-row justify="center">
            <v-col cols="12" md="6" class="text-center">
              <v-form ref="form" @submit.prevent="submit">
                <v-form-base
                  :col="{ cols: 12 }"
                  :model="model"
                  :schema="schema"
                />
                <v-btn
                  block
                  elevation="0"
                  color="success"
                  dark
                  large
                  class="rounded-lg mt-8"
                  :loading="loading"
                  type="submit"
                >
                  ارسال
                </v-btn>
              </v-form>
              <v-avatar tile size="200" class="my-16">
                <v-img :src="require('@/assets/img/box-logo-FA.png')" contain />
              </v-avatar>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import MyAxios from "@/constants/MyAxios";
import SimpleHeader from "../../components/SimpleHeader.vue";
import MyDataTable from "../../components/MyDataTable.vue";
import VFormBase from "vuetify-form-base";
import { formPostPrepare } from "@/constants/PanelFormHandler";
import { Constants } from "../../constants/GlobalConstants";

export default {
  name: "Payment",
  components: {
    SimpleHeader,
    MyDataTable,
    VFormBase,
  },
  data() {
    return {
      loading: false,
      model: {
        email: "",
        cellphone: "",
        name: "",
        desc: "",
      },
      schema: {
        name: {
          type: "CustomInput",
          label: "نام و نام خانوادگی",
          rules: [Constants.rules.required],
        },
        email: {
          type: "CustomInput",
          label: "ایمیل شما",
          rules: [Constants.rules.required, Constants.rules.email],
          // col: 9,
        },
        cellphone: {
          type: "CustomInput",
          label: "موبایل شما",
          rules: [Constants.rules.required, Constants.rules.cellphone],
        },
        desc: {
          type: "CustomInput",
          inputType: "textarea",
          label: "توضیحات",
          rules: [Constants.rules.required],
        },
      },
    };
  },
  created() {},
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        if (!this.loading) this.loading = true;

        let data = formPostPrepare(this.model);
        MyAxios.post("contacts/create", data)
          .then((response) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text:
                "با موفقیت ثبت شد. نتیجه بررسی به ایمیل شما ارسال خواهد شد.",
              type: "success",
            });
            this.model = {
              email: "",
              cellphone: "",
              name: "",
              desc: "",
            };
          })
          .catch((error) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text:
                error.response.data.msg ||
                error.response.data ||
                "مشکلی پیش آمد. لطفا دوباره تلاش کنید.",
              type: "error",
            });
          });
      }
    },
  },
};
</script>

<style scoped>
.sp-img {
  /* width: 20em;
    height: 20em; */
  max-width: 150px;
  max-height: 100px;
}
</style>
